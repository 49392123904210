<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.80706 0.593059C2.99729 0.417464 3.29385 0.429326 3.46944 0.619555L7.21944 4.68205C7.38519 4.86162 7.38519 5.13838 7.21944 5.31794L3.46944 9.38044C3.29385 9.57067 2.99729 9.58253 2.80706 9.40694C2.61683 9.23134 2.60497 8.93478 2.78056 8.74455L6.23708 5L2.78056 1.25544C2.60497 1.06521 2.61683 0.768655 2.80706 0.593059Z"
        fill="currentColor" />
    </svg>
  </i>
</template>

<style scoped lang="scss">
i {
  width: 20px;
  height: 20px;
  display: inline-block;
}
</style>
